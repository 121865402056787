<template>
  <div class="word-container">
    <div class="word"
         v-for="word in value">{{ constructSourceFullName(word) }}
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "SourcesTool",
  props: ['value'],
  methods: {
    constructSourceFullName(name) {
      const NAMES = {
        'competitor': 'Competitors',
        'competitors': 'Competitor Intelligence',
        'meta': 'Metadata',
        'suggestions': 'Suggestions',
        'rankings': 'Rankings',
        'app': 'My App',
        'related': 'Related',
      };

      return NAMES[name];
    },
  }
})
</script>

<style scoped lang="scss">
.word-container {
  display: flex;
  flex-wrap: wrap;
}

.word {
  text-transform: capitalize;
  margin-right: 6px;
  display: inline-flex;

  &:after {
    content: ',';
  }
}

.word:last-child {
  margin-right: 0;

  &:after {
    content: '';
  }
}
</style>