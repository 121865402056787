export default {
    range_sap: [
        {
            presetName: 'Low',
            presetValue: '5-29',
            minValue: 5,
            maxValue: 29,
        },
        {
            presetName: 'Medium',
            presetValue: '30-69',
            minValue: 30,
            maxValue: 69,
        },
        {
            presetName: 'Hard',
            presetValue: '70-100',
            minValue: 70,
            maxValue: 100,
        },
    ],
    range_rank: [
        {
            presetName: 'Top 3',
            presetValue: '1-3',
            minValue: 1,
            maxValue: 3,
        },
        {
            presetName: 'Top 5',
            presetValue: '1-5',
            minValue: 1,
            maxValue: 5,
        },
        {
            presetName: 'Top 10',
            presetValue: '1-10',
            minValue: 1,
            maxValue: 10,
        },
        {
            presetName: 'Top 30',
            presetValue: '1-30',
            minValue: 1,
            maxValue: 30,
        },
        {
            presetName: 'Top 50',
            presetValue: '1-50',
            minValue: 1,
            maxValue: 50,
        }
    ],
    keyword_tracked: [
        {
            presetValue: 'All',
            filterValue: 'all',
        },
        {
            presetValue: 'Added',
            filterValue: 'true',
        },
        {
            presetValue: 'Not Added',
            filterValue: 'false',
        },
    ],
    sources: [
        {
            presetValue: 'All',
            filterValue: 'all',
        },
        {
            presetValue: 'My App',
            filterValue: 'app',
        },
        {
            presetValue: 'Competitors',
            filterValue: 'competitor',
        },
    ],
    recommended_sources: [
        {
            presetValue: 'All',
            filterValue: 'all',
        },
        {
            presetValue: 'Metadata',
            filterValue: 'meta',
        },
        {
            presetValue: 'Competitors',
            filterValue: 'competitors',
        },
        {
            presetValue: 'Suggestions',
            filterValue: 'suggestions',
        },
        {
            presetValue: 'Rankings',
            filterValue: 'rankings',
        },
        {
            presetValue: 'Related',
            filterValue: 'related',
        },
    ]
};