<template>
  <div class="related-keywords"
       :class="{'first-screen': showFirstScreen}">
    <template v-if="showFirstScreen">
      <div class="new-container page-intro-search-block">
        <div class="titles-block">
          <div class="text-header">
            {{ title }}
          </div>
          <div class="text-subheader">
            Please enter a keyword, and our algorithms will analyze it in real-time to find similar keywords within the specified niche. It's important to note that this is not a semantic similarity search, so you may see branded keywords as well as keywords related to the main niche keyword.
          </div>
        </div>
        <div class="search-inputs">
          <div class="search-input-wrap">
            <form>
              <label>
                <input type="text"
                       maxlength="50"
                       class="search-input"
                       @input.prevent
                       @keydown.prevent.enter="inputSearchHandler"
                       placeholder="Enter any keyword"
                       ref="searchInput"
                       autofocus
                       v-model="currentKeyword">
              </label>
            </form>

            <base-button height="40px"
                         width="120px"
                         font-size="15px"
                         border-radius="4px"
                         :mode="'btn-standard'"
                         @click="inputSearchHandler">
              Find Similar
            </base-button>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <template v-if="searchResultsLoading">
        <div class="new-container">
          <progress-indicator :loaded="searchResultsLoading">{{ $route.meta.loaderText }}</progress-indicator>
        </div>
      </template>
      <template v-else>
        <div class="new-container">
          <h1 class="page-title">{{ title }}</h1>
        </div>
        <div class="new-container">
          <div class="related-keywords-result-search">
            <div class="search-input-wrap">
              <form>
                <label>
                  <input type="text"
                         maxlength="50"
                         class="search-input"
                         @input.prevent
                         @keydown.prevent.enter="inputSearchHandler"
                         placeholder="Search keyword..."
                         ref="liveSearchInput"
                         autofocus
                         v-model="currentKeyword">
                </label>
              </form>
              <svg-icon class="search-icon"
                        icon="search-solid"
                        @click="inputSearchHandler"/>
            </div>

            <order-radaso-button :pre-selected="['keywords-research']" />
          </div>
        </div>

        <div class="new-container">
          <div class="filters-options-block">
            <div class="left-side">

              <template v-if="!isMobile">
                <filters-component :caret-visible="false"
                                   @filters-applied="filtersApplied"
                                   :reset-filters="resetFilters"
                                   :filters-data="filtersData">
                  <template v-slot:header>
                    Filters
                  </template>
                </filters-component>
                <translate-tool :active="translateIsActive"
                                @translate-clicked="translateClickHandler"/>
              </template>
            </div>

            <div class="right-side">
              <div class="search-keywords-block">
                <basic-search-input placeholder="Search by keywords"
                                    :hide-results-block="true"
                                    @search-input-changed="searchKeywordsHandler"
                                    :init-value="searchKeywordsInput"
                                    :clear-input="searchKeywordsInput">

                  <template v-slot:search-icon>
                    <template v-if="!tableIsLoading">
                      <svg-icon v-if="searchKeywordsInput?.length === 0"
                                class="search-icon"
                                icon="search-solid"/>

                      <svg-icon v-else
                                @click="clearKeywordsSearchInput"
                                class="close-icon"
                                icon="close"/>
                    </template>
                  </template>

                  <template v-slot:preloader>
                    <div class="preloader-wrap">
                      <preloader-table-small v-if="tableIsLoading"></preloader-table-small>
                    </div>
                  </template>
                </basic-search-input>
              </div>
              <div class="table-options">
                <custom-dropdown :min-width="'36px'"
                                 :with-icon="false"
                                 :dropdown-align="'right'"
                                 :no-border="true"
                                 :with-shadow="true"
                                 :dropdown-width="'240px'"
                                 :classes="'dropdown-options-btn'">
                  <template v-slot:header>
                    <svg-icon icon="setting"/>
                  </template>
                  <template v-slot:content>
                    <div class="select-options">
                      <div class="option"
                           v-for="(item, idx) in hideableHeaders"
                           :key="idx">

                        <data-table-checkbox v-if="!item.isFrozen"
                                             :checked="item.isActive"
                                             @input-checked="tableColumnChecked(item)">
                          <span v-html="item.text"></span>
                        </data-table-checkbox>
                      </div>
                    </div>
                  </template>
                </custom-dropdown>

                <div v-if="userSubscription?.plan_name === 'Free'"
                     @click="openPlansModal"
                     class="download-button">
                  <svg-icon icon="download"/>
                </div>
                <a v-else
                   :href="fetchDownloadData"
                   class="download-button"
                   download>
                  <svg-icon icon="download"/>
                </a>
              </div>
            </div>
          </div>

          <template v-if="isMobile">
            <div class="filters-mobile-options-block">
              <filters-component :caret-visible="false"
                                 @filters-applied="filtersApplied"
                                 :reset-filters="resetFilters"
                                 :filters-data="filtersData">
                <template v-slot:header>
                  Filters
                </template>
              </filters-component>
              <translate-tool :active="translateIsActive"
                              @translate-clicked="translateClickHandler"/>
            </div>
          </template>
        </div>

        <div class="new-container">
          <template v-if="tableIsLoading">
            <progress-indicator :loaded="tableIsLoading">Loading Related Keywords...</progress-indicator>
          </template>
          <template v-else>
            <template v-if="keywordsList?.length > 0">
              <keyword-table-component :headers="headers"
                                    :table-items="keywordsList"
                                    :table-full-items="keywordsFullList"
                                    :table-pagination="tablePagination"
                                    :sort-data="sortData"
                                    :current-app="currentApp"
                                    :current-country="currentCountry"
                                    :search-input-value="searchKeywordsInput"
                                    :show-blurred-block="limitedResult"
                                    @save-keywords="saveAddedKeywords"
                                    @keywords-added="keywordsAdded"
                                    @keyword-removed="keywordRemoved"
                                    @update-sort="updateSort"
                                    @select-all-clicked="selectAllKeywords"
                                    @unselect-all-clicked="unSelectAllKeywords"
                                    @table-item-checked="tableItemChecked"
              />
            </template>
            <template v-else>
              <server-error v-if="false"
                            button-text="Retry"
                            button-id="related-retry-button"
                            @clicked="inputSearchHandler">
                <template v-slot:title>
                  TIMEOUT ERROR
                </template>
                <template v-slot:text>
                  <p>
                    Estimated Impressions Report involve real-time analysis of millions of keywords.
                  </p>
                  <p>
                    We are sorry, our system is experiencing overload.
                  </p>
                  <p class="small">
                    We are actively working to prevent this in the future.
                  </p>
                  <p>
                    Please try again 🙏🏼
                  </p>
                </template>
              </server-error>
              <div v-else class="no-data-block">
                <img src="@/assets/images/tables/no_data.svg">
                <div class="no-data-content">
                  <div class="no-data-title">Try to analyze other keywords</div>
                  <div class="no-data-subtitle">
                    No apps rank in the store for this keyword. Consider analyzing a different keyword.
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import KeywordTableComponent from "@/components/KeywordTable/index.vue";
import {relatedKeywordsTableConfig} from "@/configs/page-tables-configs/related-keywords-table";
import {relatedKeywordsTableConfigGP} from "@/configs/page-tables-configs/related-keywords-table-gp";
import {httpRequest} from "@/api";
import {GenerateQueryUrl, RelatedKeywordsObject, SplitArrays} from "@/utils/factories";
import {mapGetters} from "vuex";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import FiltersComponent from "@/components/UI/FiltersComponent/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import TheHeader from "@/components/TheHeader/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import NoDataFoundBlock from "@/components/DataTables/NoDataFoundBlock/index.vue";
import filterPresetsConst from "@/api/modules/filterPresetsConst";
import TableWrapComponent from "@/components/KeywordTable/index.vue";
import {getCookie} from "@/utils/utils";
import ServerError from "@/components/ServerError/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'RelatedKeywords',
  components: {
    OrderRadasoButton,
    SvgIcon,
    ServerError,
    TableWrapComponent,
    'basic-search-input': BasicSearchInput,
    'the-header': TheHeader,
    'preloader-table-small': PreloaderTableSmall,
    'progress-indicator': ProgressIndicator,
    'filters-dropdown': FiltersDropdown,
    'custom-dropdown': CustomDropdown,
    'data-table-checkbox': TableCheckbox,
    FiltersComponent,
    KeywordTableComponent,
    NoDataFoundBlock,
    TranslateTool,
  },
  data() {
    return {
      title: 'Related Keywords Intelligence',
      loaderText: '',
      headers: relatedKeywordsTableConfig.headers,
      searchResultsLoading: false,
      tablePagination: {
        current_page: 1,
        from: 1,
        to: 50,
        per_page: 50,
        last_page: 1,
        total: null,
        links: [
          {
            active: false,
            label: "&laquo; Previous",
          },
          {
            active: true,
            label: "1",
          },
          {
            active: false,
            label: "Next &raquo;",
          }
        ],
        skip: 0,
        size: 50,
      },
      searchKeywordsInput: '',
      filtersData: [],
      sortData: {
        sort_by: 'sap',
        sort: 'sap',
        order: 'desc'
      },
      appliedFiltersObject: {},
      initialActiveFiltersObj: {},
      resetFilters: 0,
      keywordsFullList: [],
      keywordsList: [],
      checkedArray: [],
      checkAllIsActive: false,
      tableIsLoading: true,
      translateIsActive: false,
      showFirstScreen: true,
      currentKeyword: '',
      showTimeoutError: false,
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    let keywordParam = urlParams.get('keyword');
    if (keywordParam) {
      this.currentKeyword = keywordParam;
      this.inputSearchHandler();
    }
  },
  methods: {
    async fetchTableData(mode) {
      this.tableIsLoading = true;
      const url = this.getListUrl() + this.generateFiltersUrl(mode);
      const result = await httpRequest('GET', url);

      let tempFiltersData = [];

      if (this.currentApp?.store?.key !== 'GOOGLE_PLAY') {
        tempFiltersData = [
          {
            type: 'range',
            title: 'Popularity',
            filter: 'range_sap',
            min: (result?.filter_value_limits?.min_sap || this.appliedFiltersObject['filter[range_sap][from]']) ?? 0,
            max: (result?.filter_value_limits?.max_sap || this.appliedFiltersObject['filter[range_sap][to]']) ?? 100,
            maxLimit: 100,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_sap][from]'] || this.appliedFiltersObject['filter[range_sap][from]'],
            presetsData: filterPresetsConst.range_sap,
          },
          {
            type: 'range',
            title: 'App Rank',
            filter: 'range_rank',
            min: (result?.filter_value_limits?.min_rank || this.appliedFiltersObject['filter[range_rank][from]']) ?? 0,
            max: (result?.filter_value_limits?.max_rank || this.appliedFiltersObject['filter[range_rank][to]']) ?? 50,
            maxLimit: 50,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_rank][from]'] || this.appliedFiltersObject['filter[range_rank][from]'],
            presetsData: filterPresetsConst.range_rank,
          },
          {
            type: 'range',
            title: 'Est. Daily Impr.',
            filter: 'range_impression',
            min: (result?.filter_value_limits?.min_daily_impression || this.appliedFiltersObject['filter[range_impression][from]']) ?? 0,
            max: result?.filter_value_limits?.max_daily_impression || this.appliedFiltersObject['filter[range_impression][to]'],
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_impression][from]'] !== undefined || this.appliedFiltersObject['filter[range_impression][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'range',
            title: 'Difficulty',
            filter: 'range_difficulty',
            min: (result?.filter_value_limits?.min_difficulty || this.appliedFiltersObject['filter[range_difficulty][from]']) ?? 0,
            max: result?.filter_value_limits?.max_difficulty || this.appliedFiltersObject['filter[range_difficulty][to]'],
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_difficulty][from]'] !== undefined || this.appliedFiltersObject['filter[range_difficulty][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'range',
            title: 'Comp. in Top 10',
            filter: 'range_top_competitors',
            min: (result?.filter_value_limits?.range_top_competitors || this.appliedFiltersObject['filter[range_top_competitors][from]']) ?? 0,
            max: (result?.filter_value_limits?.range_top_competitors || this.appliedFiltersObject['filter[range_top_competitors][to]']) ?? 10,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_top_competitors][from]'] !== undefined || this.appliedFiltersObject['filter[range_top_competitors][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'range',
            title: 'NonComp. in Top 10',
            filter: 'range_top_non_competitors',
            min: (result?.filter_value_limits?.range_top_non_competitors || this.appliedFiltersObject['filter[range_top_non_competitors][from]']) ?? 0,
            max: (result?.filter_value_limits?.range_top_non_competitors || this.appliedFiltersObject['filter[range_top_non_competitors][to]']) ?? 10,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_top_non_competitors][from]'] !== undefined || this.appliedFiltersObject['filter[range_top_non_competitors][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'radio',
            title: 'Words count',
            filter: 'range_words_count',
            value: 0,
            min: (this.initialActiveFiltersObj['filter[range_words_count][from]'] || this.appliedFiltersObject['filter[range_words_count][from]']) ?? 0,
            max: (this.initialActiveFiltersObj['filter[range_words_count][to]'] || this.appliedFiltersObject['filter[range_words_count][to]']) ?? 100,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_words_count][from]'] || this.appliedFiltersObject['filter[range_words_count][from]'],
            presetsData: null,
          },
        ];
      } else {
        tempFiltersData = [
          {
            type: 'range',
            title: 'Popularity',
            filter: 'range_sap',
            min: (result?.filter_value_limits?.min_sap || this.appliedFiltersObject['filter[range_sap][from]']) ?? 0,
            max: (result?.filter_value_limits?.max_sap || this.appliedFiltersObject['filter[range_sap][to]']) ?? 100,
            maxLimit: 100,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_sap][from]'] || this.appliedFiltersObject['filter[range_sap][from]'],
            presetsData: filterPresetsConst.range_sap,
          },
          {
            type: 'range',
            title: 'App Rank',
            filter: 'range_rank',
            min: (result?.filter_value_limits?.min_rank || this.appliedFiltersObject['filter[range_rank][from]']) ?? 0,
            max: (result?.filter_value_limits?.max_rank || this.appliedFiltersObject['filter[range_rank][to]']) ?? 50,
            maxLimit: 50,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_rank][from]'] || this.appliedFiltersObject['filter[range_rank][from]'],
            presetsData: filterPresetsConst.range_rank,
          },
          {
            type: 'range',
            title: 'Comp. in Top 10',
            filter: 'range_top_competitors',
            min: (result?.filter_value_limits?.range_top_competitors || this.appliedFiltersObject['filter[range_top_competitors][from]']) ?? 0,
            max: (result?.filter_value_limits?.range_top_competitors || this.appliedFiltersObject['filter[range_top_competitors][to]']) ?? 10,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_top_competitors][from]'] !== undefined || this.appliedFiltersObject['filter[range_top_competitors][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'range',
            title: 'NonComp. in Top 10',
            filter: 'range_top_non_competitors',
            min: (result?.filter_value_limits?.range_top_non_competitors || this.appliedFiltersObject['filter[range_top_non_competitors][from]']) ?? 0,
            max: (result?.filter_value_limits?.range_top_non_competitors || this.appliedFiltersObject['filter[range_top_non_competitors][to]']) ?? 10,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_top_non_competitors][from]'] !== undefined || this.appliedFiltersObject['filter[range_top_non_competitors][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'range',
            title: 'Difficulty',
            filter: 'range_difficulty',
            min: (result?.filter_value_limits?.min_difficulty || this.appliedFiltersObject['filter[range_difficulty][from]']) ?? 0,
            max: result?.filter_value_limits?.max_difficulty || this.appliedFiltersObject['filter[range_difficulty][to]'],
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_difficulty][from]'] !== undefined || this.appliedFiltersObject['filter[range_difficulty][from]'] !== undefined,
            presetsData: null,
          },
          {
            type: 'radio',
            title: 'Words count',
            filter: 'range_words_count',
            value: 0,
            min: (this.initialActiveFiltersObj['filter[range_words_count][from]'] || this.appliedFiltersObject['filter[range_words_count][from]']) ?? 0,
            max: (this.initialActiveFiltersObj['filter[range_words_count][to]'] || this.appliedFiltersObject['filter[range_words_count][to]']) ?? 100,
            isInitiallyActive: this.initialActiveFiltersObj['filter[range_words_count][from]'] || this.appliedFiltersObject['filter[range_words_count][from]'],
            presetsData: null,
          },
        ];
      }

      this.keywordsList = RelatedKeywordsObject(result?.list);
      this.generateTableWithFakeData();
      this.filtersData = [...tempFiltersData];
      this.updatePagination({total: result?.total, page: this.tablePagination.current_page});
      this.searchResultsLoading = false;
      this.tableIsLoading = false;
      this.showTimeoutError = result?.is_timeout;
    },
    async updateSort(data) {
      if (JSON.stringify(data) === '{}') {
        return;
      }

      this.tableIsLoading = true;
      this.translateIsActive = false;

      let sortData = {...this.sortData};

      //inverting of app-rank column
      let sortDataOrder = data?.order ?? sortData.order;

      if (data?.sort === 'latest_rank') {
        sortDataOrder = sortDataOrder === 'desc' ? 'asc' : 'desc';
      }

      sortData.order = sortDataOrder;
      sortData.sort = data?.sort ?? sortData?.sort;
      sortData.sort_by = data?.sort ?? sortData?.sort;
      this.tablePagination.current_page = 1;
      this.tablePagination.skip = 0;

      this.sortData = {...sortData};

      if (data?.page || data?.per_page) {
        let perPage = +this.tablePagination.per_page ?? +data?.per_page;
        let currPage = +data.page ?? 0;

        if (data?.per_page) {
          perPage = +data.per_page;
        }
        const page = currPage === 1 ? currPage : (currPage > 0 ? currPage : 1) - 1;
        this.tablePagination.current_page = +data?.page ?? 1;
        this.tablePagination.size = data?.per_page ?? this.tablePagination.size;
        this.tablePagination.per_page = data?.per_page ?? this.tablePagination.size;

        if (+data?.page === 1) {
          this.tablePagination.skip = 0;
        } else {
          this.tablePagination.skip = page * perPage;
        }

        this.updatePagination(data);
      }
      await this.fetchTableData();
      this.tableIsLoading = false;
      this.checkAllIsActive = false;
    },
    async filtersApplied(e, filter) {
      this.appliedFiltersObject = {...e};
      if (JSON.stringify(e) === '{}') {
        this.initialActiveFiltersObj = {};
        this.appliedFiltersObject = {};
        await this.updateSort();
      }

      this.updatePagination({page: 1});
      await this.updateSort({
        ...e,
      });
    },
    async searchKeywordsHandler(inputValue) {
      if (this.limitedResult) {
        this.upgradeNowClicked();
        return;
      }
      this.tablePagination.skip = 0;
      this.tablePagination.current_page = 1;
      this.tableIsLoading = true;
      this.searchKeywordsInput = inputValue;

      await this.fetchTableData();
    },
    async clearKeywordsSearchInput() {
      await this.searchKeywordsHandler('');
    },
    async translateClickHandler() {
      let urlQuery = '?locale_code=' + 'en' + '&store_key=' + this.currentApp?.store?.key;
      this.translateIsActive = !this.translateIsActive;
      let translatedResponse;

      if (this.keywordsList?.length > 100) {
        const separatedArrays = SplitArrays(this.keywordsList, 100);

        if (separatedArrays?.length === 0) {
          return;
        }

        let queryArray = [];

        separatedArrays.forEach(async array => {
          let urlQuery = '?locale_code=' + 'en' + '&store_key=' + this.currentApp?.store?.key;
          array.forEach((keyword, idx) => {
            urlQuery += `&keywords[${idx}]=${encodeURIComponent(keyword?.keyword?.term)}`;
          });
          queryArray.push({
            url: process.env.VUE_APP_API_URL + this.$ApiUrls.translateUrls.TRANSLATE_KEYWORDS + urlQuery,
            method: 'GET',
          });
        });
        const tempTranslatedResponse = await this.fetchRequestsBundle(queryArray);

        tempTranslatedResponse.forEach(item => {
          translatedResponse = {...translatedResponse, ...item};
        });

      } else {
        this.keywordsList.forEach((keyword, idx) => {
          urlQuery += `&keywords[${idx}]=${encodeURIComponent(keyword?.keyword?.term)}`;
        });

        translatedResponse = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.translateUrls.TRANSLATE_KEYWORDS + urlQuery);
      }

      const translatedKeywordsList = this.keywordsList.map(item => {
        let keywordToTranslate;
        for (const key in translatedResponse) {
          if (key === item?.keyword?.term) {
            keywordToTranslate = translatedResponse[key];
          }
        }

        return {
          ...item,
          translate: this.translateIsActive ? keywordToTranslate : null,
        }
      });

      this.keywordsList = [...translatedKeywordsList];
    },
    async keywordsAdded(list) {
      this.markKeywords(Object.keys(list));
      await this.fetchDataCounts();
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
    },
    async keywordRemoved(id, term) {

    },
    markKeywords(keywordsArray) {
      let currentArray = this.keywordsFullList?.length > 0 ? 'keywordsFullList' : 'keywordsList';
      let tempArray = this[currentArray]?.map(item => {
        let checked = this.checkedArray.find(checkedItem => checkedItem?.keyword?.term === item?.keyword?.term);
        return {
          ...item,
          checked: checked?.checked ?? false,
          keyword: {
            ...item?.keyword,
            is_tracked: item?.keyword?.is_tracked || keywordsArray.includes(item?.keyword?.term),
          }
        }
      });
      this[currentArray] = [...tempArray];
    },
    async saveAddedKeywords(keywordsArray) {
      const currentAppId = getCookie('currentAppId');
      if (currentAppId && keywordsArray?.length > 0) {
        this.closeModal('showAddKeywordsModal');
        await this.saveAddedKeywordsFunc(keywordsArray, currentAppId, this.currentCountry?.code);

        this.markKeywords(keywordsArray);

        await this.fetchDataCounts();
        this.$store.dispatch('UPDATE_USER_LIMITS');
        this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      }
    },
    async selectAllKeywords() {
      const url = this.getListUrl() + this.generateFiltersUrl('fetch-all');
      const result = await httpRequest('GET', url);
      const allKeywordsList = RelatedKeywordsObject(this.currentApp, result?.list, this.countryList);
      this.keywordsFullList = allKeywordsList.map(item => {
        return {
          ...item,
          checked: true
        }
      });
      this.checkedArray = [...this.keywordsFullList];
      this.checkAllIsActive = true;
    },
    updatePagination(data) {
      const tablePagination = {...this.tablePagination};
      for (const key in data) {
        if (key === 'page') {
          tablePagination.current_page = data[key];
        } else if (key === 'per_page') {
          tablePagination[key] = data[key];
          tablePagination.current_page = 1;
        } else {
          tablePagination[key] = data[key];
        }
      }

      const from = (tablePagination?.per_page * +tablePagination?.current_page) - +tablePagination?.per_page;
      const to = +tablePagination?.current_page * +tablePagination?.per_page;
      tablePagination.from = from <= 0 ? 1 : from;
      tablePagination.to = to > tablePagination.total ? tablePagination.total : to;
      tablePagination.current_page = +tablePagination.current_page;

      let linksCount = +tablePagination.total / +tablePagination?.per_page;
      linksCount = Math?.ceil(linksCount);
      tablePagination.last_page = linksCount;

      const maxValue = 6;
      let links = [];

      if (tablePagination?.current_page <= 3 && linksCount >= maxValue) {
        for (let i = 1; i <= maxValue; i++) {
          links.push(
            {
              active: i === tablePagination?.current_page,
              label: i + '',
            }
          );
        }
      } else {
        links = [
          {
            active: true,
            label: tablePagination?.current_page + '',
          },
        ];

        if (tablePagination?.current_page - 1 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 1 + '',
          });
        }

        if (tablePagination?.current_page - 2 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 2 + '',
          });
        }

        if (tablePagination?.current_page + 1 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 1 + '',
          });
        }

        if (tablePagination?.current_page + 2 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 2 + '',
          });
        }
      }

      tablePagination.links = [...links];

      let leftArrowLink = {
        active: false,
        label: "&laquo; Previous",
      };

      let rightArrowLink = {
        active: false,
        label: "Next &raquo;",
      };

      tablePagination.links.unshift(leftArrowLink);
      tablePagination.links.push(rightArrowLink);

      this.tablePagination = {...tablePagination};
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    unSelectAllKeywords(checkedArray) {
      this.keywordsFullList = [];
      this.checkAllIsActive = false;
      this.checkedArray = [...checkedArray];
    },
    tableItemChecked(checkedArray) {
      this.checkAllIsActive = false;
      this.keywordsFullList = [];
      this.checkedArray = checkedArray.filter(item => item?.checked);
    },
    resetTablePagination() {
      this.tablePagination.size = 50;
      this.tablePagination.per_page = 50;
      this.tablePagination.skip = 0;
      this.tablePagination.current_page = 1;
    },
    tableColumnChecked(item) {
      const colIndex = this.headers.findIndex(colItem => colItem.text === item.text);
      const tableCol = this.headers.map(item => item);
      if (colIndex >= 0 && !item.isFrozen) {
        tableCol[colIndex].isActive = !tableCol[colIndex].isActive;
      }

      this.headers = tableCol.map(item => item);
    },
    generateFiltersUrl(mode = 'default') {
      let size = mode !== 'fetch-all' ? this.tablePagination.size : this.tablePagination?.total;
      let skip = mode !== 'fetch-all' ? this.tablePagination.skip : 0;
      let sortBy = this.sortData?.sort_by ?? 'sap';
      let urlQuery = '?app_id=' + this.currentApp?.id
        + '&country_code=' + this.currentCountry?.code
        + '&keyword=' + this.currentKeyword
        + '&sort_by=' + sortBy
        + '&order=' + this.sortData?.order
        + '&size=' + size
        + '&skip=' + skip;

      if (this.searchKeywordsInput !== '') {
        urlQuery += '&filter[like_keyword][query]=' + this.searchKeywordsInput;
      }

      if (mode === 'default') {
        let filterUrl = GenerateQueryUrl(this.appliedFiltersObject);

        if (filterUrl !== '') {
          urlQuery += filterUrl;
        }
      }

      return urlQuery;
    },
    setHeadersToDefault() {
      const headers = this.headers.map(item => {
        return {
          ...item,
          isActive: true,
        }
      });

      this.headers = [...headers];
    },
    async inputSearchHandler() {
      if (this.currentKeyword.length === 0) {
        return;
      }

      this.showFirstScreen = false;
      this.searchResultsLoading = true;

      this.headers = this.currentApp?.store?.key === 'APP_STORE' ? [...relatedKeywordsTableConfig.headers] : [...relatedKeywordsTableConfigGP.headers];

      await this.resetResult();
    },
    async resetResult() {
      this.translateIsActive = false;
      this.resetFilters++;

      this.sortData = {
        sort_by: 'sap',
        sort: 'sap',
        order: 'desc'
      };

      this.setHeadersToDefault();
      this.resetTablePagination();

      await this.fetchTableData('reset');
    },
    getListUrl() {
      return process.env.VUE_APP_API_URL + this.$ApiUrls.relatedKeywords.RELATED_KEYWORDS;
    },
    generateFakeTableItemsData(amount = 4) {
      let fakeArray = [];
      for (let i = 0; i < amount; i++) {
        fakeArray.push({
          keyword: {
            term: Math.random().toString(12).substring(2, 10) + Math.random().toString(12).substring(2, 10),
          },
          sap: Math.floor(Math.random() * 10) + 1,
          daily_impression: Math.random().toString(12).substring(2, 5) + Math.random().toString(12).substring(2, 5),
          rank: {
            value: Math.floor(Math.random() * 10) + 1,
            dynamic: '',
          },
          isFakeData: true,
        });
      }

      return fakeArray;
    },
    generateTableWithFakeData() {
      let tempTable = [];

      if (this.limitedResult) {
        tempTable = [...this.keywordsList, ...this.generateFakeTableItemsData()];
        this.keywordsList = [...tempTable];
      }
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'userSubscription',
    ]),
    fetchDownloadData() {
      if (this.currentApp?.id) {
        const urlQuery = this.generateFiltersUrl();
        return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_RELATED + urlQuery;
      }
    },
    hideableHeaders() {
      return this.headers.filter(headerItem => headerItem?.isHideable);
    },
    limitedResult() {
      return this.keywordsList?.length > 0 && this.userSubscription?.plan_name === 'Free';
    },
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (!this.showFirstScreen && newVal?.id !== oldVal?.id) {
        this.headers = this.currentApp?.store?.key === 'APP_STORE' ? [...relatedKeywordsTableConfig.headers] : [...relatedKeywordsTableConfigGP.headers];
        await this.resetResult();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (!this.showFirstScreen && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        await this.resetResult();
      }
    },
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
